import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import View from "../components/View"
import Status from "../components/Status"
import '../styles/global.scss'

const Index = () => (
  <Layout>
    <Status />
    <View title="NLDamp Internal Tools">
      <p>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline float-right" type="button">
          <Link to="/app/dashboard">Go to Dashboard</Link>
        </button>
      </p>
    </View>
  </Layout>
)

export default Index
